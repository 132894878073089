<template>
	<CForm @submit.prevent="checkForm">
		<div class="form-group">
			<MaterialInput
				v-if="shouldShowField('fullname')"
				id="fullname"
				v-model="form.fullname.value"
				type="text"
				:label="$t('registration.full-name')"
				:error="form.fullname.error"
			/>

			<MaterialInput
				v-if="shouldShowField('email')"
				id="email"
				v-model="form.email.value"
				type="text"
				:label="$t('registration.email')"
				:error="form.email.error"
			/>

			<MaterialPhoneInput
				v-if="shouldShowField('mobilenumber')"
				:default-country="form.country.value"
				:mobilenumber="form.phone.value"
				:error="form.phone.error"
				:label="$t('profile-info.phone.title')"
				placeholder="+132 345 567"
				@set-mobile-number="setMobileNumber"
			/>

			<MaterialSelect
				v-if="shouldShowField('country')"
				id="country"
				v-model="form.country.value"
				:label="$t('registration.country')"
				:error="form.country.error"
			>
				<option v-for="(country, countryIndex) in getCountries" :key="countryIndex" :value="country.isoCode">
					{{ country.name }}
				</option>
			</MaterialSelect>
		</div>

		<FormError v-if="networkError" :error="$t('errors.network-error')" />

		<CButton primary full-width :loading="loading" type="submit">
			{{ $t('global.update') }}
		</CButton>
	</CForm>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import MaterialInput from '@/components/material-input/MaterialInput';
import MaterialSelect from '@/components/material-select/MaterialSelect';
import CButton from '@/shared/cbutton/CButton';
import CForm from '@/shared/cform/CForm';
import MaterialPhoneInput from '@/shared/material-phone-input/MaterialPhoneInput';

const FormError = () => import('../forms/FormError.vue');

export default {
	name: 'CompleteAccountForm',
	components: {
		MaterialPhoneInput,
		CForm,
		CButton,
		MaterialSelect,
		MaterialInput,
		FormError,
	},
	props: {
		formFields: Array,
		step: {
			validator(value) {
				return ['CompleteAccountBeforeDepositForm', 'CompleteAccountBeforeWithdrawalForm'].indexOf(value) !== -1;
			},
		},
	},
	data() {
		return {
			form: {
				fullname: {
					value: '',
					error: null,
				},
				email: {
					value: '',
					error: null,
				},
				country: {
					value: null,
					error: null,
				},
				phone: {
					value: '',
					error: null,
					isValid: false,
				},
			},
			loading: false,
			networkError: false,
		};
	},
	mounted() {
		this.updateUserState(this.getUser);
	},
	computed: {
		...mapGetters(['getUser', 'getCountries']),
	},
	methods: {
		...mapActions(['updateUser']),
		updateUserState(user) {
			this.form.fullname.value = user.name;
			this.form.email.value = user.email;
			this.form.phone.value = user.mobilenumber;
			this.form.country.value = user.country;
		},
		shouldShowField(field) {
			return Boolean(this.formFields.find((item) => item.field === field));
		},
		async submitForm() {
			const { fullname, email, country, phone } = this.form;

			this.networkError = false;

			try {
				this.loading = true;

				await this.updateUser({
					fullname: this.shouldShowField('fullname') ? fullname.value : undefined,
					email: this.shouldShowField('email') ? email.value : undefined,
					country: this.shouldShowField('country') ? country.value : undefined,
					mobilenumber: this.shouldShowField('mobilenumber') ? phone.value : undefined,
					step: this.step,
				});

				this.$emit('done');
			} catch (error) {
				console.error(error);
				if (error.response?.data?.errors) {
					error.response.data.errors.map((value) => {
						this.form[value.param].error = value.msg;

						if (value.param === 'mobilenumber') {
							this.form.phone.error = value.msg;
						}
					});
				} else {
					Sentry.captureException(error);
					this.networkError = true;
				}
			} finally {
				this.loading = false;
			}
		},
		checkForm() {
			this.clearErrors();

			const { phone } = this.form;

			if (this.shouldShowField('mobilenumber') && !phone.isValid) {
				phone.error = this.$t('profile-info.phone.invalid');
			}

			const hasErrors = Object.entries(this.form).filter((p) => p[1].error).length !== 0;

			if (!hasErrors) {
				this.submitForm();
			}
		},
		setMobileNumber(event) {
			this.form.phone.value = event.number.input;
			this.form.phone.isValid = event.isValid;
		},
		clearErrors() {
			for (const param in this.form) {
				this.form[param].error = '';
			}
			this.error = null;
			this.success = false;
		},
	},
};
</script>
